.ct{
    @include back_image;
    min-height: 400px;
}
.cta_home{
    background-image: url('../../img/general/span_stthen.jpg');
    background-size: auto;
        background-attachment: fixed;
}
.cta_live_betting{
background-image: url('../../img/general/cta_live_bet.jpg');
}