.btn_white{
    background: $white!important;
    &:hover{
        background: $black!important;
        color: $white!important;
    }
}
.btn_amarillo{
    background: $amarillo!important;
    &:hover{
        background: $black!important;
        color: $white!important;
    }
}