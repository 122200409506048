.bg_amarillo{
    background: $amarillo;
}
.bg_gris_oscuro{
    background: $gris_oscruto;
}
.bg_gradient{
    background: $bg_gradiante;
}
.divider_gradient{
    background: $span_gradiente;
    height: 4px;
    width: 100%;
}
.divider_amarillo{
    background: $amarillo;
    height: 2px;
    width: 100%;
}
.link_oscuro{
    color: $black;
    text-decoration: none;
    &:hover{
        color: $white;
    }
}