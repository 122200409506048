.promo {
    position: relative;

    &:hover {
        .promo_back {
            opacity: 1;
        }

        .promo_front {
            img {
                filter: blur(2px);
            }
        }
    }
}

.promo_front {
    img {
        border-radius: 10px;
        box-shadow: 0 0 5px $black;
        transition: all 0.2s linear;
    }
}

.promo_back {
    position: absolute;
    color: #fff;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba($black, .80);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s linear;
    border-radius: 10px;
    opacity: 0;
}