.service{
    @include back_image;
    min-height: 300px;
    position: relative;
    transition: all .1s linear;
    h2{
        position: absolute;
        bottom: 10px;
        transition: all .1s linear;
    }
    &:hover{
        box-shadow: 0 0 5px $black;
        border-radius: 5px;
        h2{
            bottom:40%;
            background:rgba($amarillo, .5) ;
        }
    }
}
.service_sportsbook{
    background-image: url('../../img/services/sportsbook.jpg');
}
.service_poker{
    background-image: url('../../img/services/poker.jpg');
}
.service_live_casino{
    background-image: url('../../img/services/live_casino.jpg');
}
.service_online_casino{
    background-image: url('../../img/services/online_casino.jpg');
}
.service_live_betting{
    background-image: url('../../img/services/live_betting.jpg');
}
.service_racing{
    background-image: url('../../img/services/racing.jpg');
}
.service_prop_builder{
    background-image: url('../../img/services/prop_builder.jpg');
}
.service_call_center{
    background-image: url('../../img/services/call_center.jpg');
}