.slider {
    min-height: 450px;
    @include back_image;
}

//slider home
.slider_1 {
    background-image: url('../../img/slider_home/slider-1.jpg');
}

.slider_2 {
    background-image: url('../../img/slider_home/slider-2.jpg');
}

.slider_3 {
    background-image: url('../../img/slider_home/slider-3.jpg');
}

//slider paginas internas
.slider_sportsbook {
    background-image: url('../../img/sliderspaginas/slider-sportsbook.jpg');
    @include justy;
}

.slider_online_casino {
    background-image: url('../../img/sliderspaginas/online-casino.jpg');
    @include justy;
}

.slider_racing {
    background-image: url('../../img/sliderspaginas/racing.jpg');
    @include justy;
}

.slider_live_betting {
    background-image: url('../../img/sliderspaginas/live-betting.jpg');
    @include justy;
}

.slider_live_casino {
    background-image: url('../../img/sliderspaginas/live-casino.jpg');
    @include justy;
}

.slider_poker {
    background-image: url('../../img/sliderspaginas/poker.jpg');
    @include justy;
}

.slider_about {
    background-image: url('../../img/sliderspaginas/about-us.jpg');
    @include justy;
}

.slider_bonus {
    background-image: url('../../img/sliderspaginas/bonus.jpg');
    @include justy;
}